import {DEV} from '../dev';

let amplifyConfig = {
    aws_app_analytics: "disable",
    aws_user_pools: "enable",
    aws_user_pools_id: "eu-west-1_LoXZ5ZA8c",
    aws_user_pools_mfa_type: "ON",
    aws_user_pools_web_client_id: "3fes45kan3o48oj2qdk757bfvv",
    aws_user_settings: "enable",
  
    cookieStorage: {
      domain: ".carlosrodriguez.tech",
      expires: 60,
      sameSite: "strict",
      secure: true,
    },
  };
  
  if (DEV) {
    amplifyConfig = {
      ...amplifyConfig,
      cookieStorage: {
        domain: "localhost",
        expires: 60,
        sameSite: "strict",
        secure: false,
      },
    };
  }
  
export default amplifyConfig;
